export function formatFractionNumber(number, language) {
  const roundedNumber = Math.round((number + Number.EPSILON) * 1000) / 1000
  const wholePart = Math.trunc(roundedNumber)
  const fractionalPart = roundedNumber - wholePart

  const frs = [
    {
      value: 0,
      label: ''
    },
    {
      value: 1 / 2,
      label: '½'
    },
    {
      value: 1 / 3,
      label: '⅓'
    },
    {
      value: 2 / 3,
      label: '⅔'
    },
    {
      value: 1 / 4,
      label: '¼'
    },
    {
      value: 3 / 4,
      label: '¾'
    },
    {
      value: 1 / 5,
      label: '⅕'
    },
    {
      value: 2 / 5,
      label: '⅖'
    },
    {
      value: 3 / 5,
      label: '⅗'
    },
    {
      value: 4 / 5,
      label: '⅘'
    },
    {
      value: 1 / 6,
      label: '⅙'
    },
    {
      value: 5 / 6,
      label: '⅚'
    },
    {
      value: 1 / 7,
      label: '⅐'
    },
    {
      value: 1 / 8,
      label: '⅛'
    },
    {
      value: 3 / 8,
      label: '⅜'
    },
    {
      value: 5 / 8,
      label: '⅝'
    },
    {
      value: 7 / 8,
      label: '⅞'
    },
    {
      value: 1 / 9,
      label: '⅑'
    },
    {
      value: 1,
      label: '1'
    }
  ]

  frs.sort((a, b) => a.value - b.value)

  const idx = frs.findIndex(({ value }) => value > fractionalPart)

  let transformedFraction
  if (idx === -1) {
    transformedFraction = ''
  } else if (idx === 0) {
    transformedFraction = frs[idx].label
  } else {
    const minValue = frs[idx - 1]
    const maxValue = frs[idx]
    const delta1 = fractionalPart - minValue.value
    const delta2 = maxValue.value - fractionalPart

    if (delta1 < delta2) {
      transformedFraction = minValue.label
    } else {
      transformedFraction = maxValue.label
    }
  }

  if (wholePart && !transformedFraction) {
    return number.toLocaleString(language, { maximumFractionDigits: 2 })
  } if (transformedFraction === '1') {
    return `${(wholePart || '') + Number(transformedFraction)}`
  }
  return `${wholePart || ''}${transformedFraction || ''}`.trim()
}

export default {
  formatFractionNumber
}
