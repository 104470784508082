<template>
  <div>
    <button
      v-if="model.adimoId"
      data-type="recipe-widget"
      :data-touchpoint-id="model.adimoId"
      class="c-button c-button--solid c-button--block u-mb--s adimo-container"
    >
      <Icon
        :icon="iconShoppingBag"
        :size="16"
      />
      {{ model.buyIngredientsLabel }}
    </button>

    <button
      class="c-button c-button--block"
      @click="modal.onOpenModal()"
    >
      <Icon
        :icon="iconCatalog"
        :size="16"
      />
      {{ model.shareShoppingListLabel }}
    </button>

    <div
      v-if="!model.adimoId && model.whereToBuyPageUrl"
      class="u-font-size-xlarge u-mt--m"
    >
      <a :href="model.whereToBuyPageUrl">
        {{ model.whereToBuyLabel }}
      </a>
    </div>

    <!-- Share shopping list modal -->
    <Modal ref="modal">
      <div class="desktop:u-px--m">
        <div class="u-flex u-flex-wrap u-flex-align-baseline u-mb--s">
          <h3 class="u-font-size-xxlarge u-mr--s u-mb--0">
            {{ model.shoppingListLabel }}
          </h3>
          <div class="u-font-size-medium u-text-uppercase">
            {{ portions }} {{ amountText }}
          </div>
        </div>

        <div class="c-recipe-ingredients__list u-mb--s">
          <recipe-ingredient-group
            v-for="ingredientGroup in scaledIngredientsGroups"
            :key="ingredientGroup.index"
            :model="ingredientGroup"
            :show-group-name="false"
          />
        </div>

        <div>
          <button
            v-if="model.adimoId"
            data-type="recipe-widget"
            :data-touchpoint-id="model.adimoId"
            class="c-button c-button--solid c-button--block-mobile u-mb--s u-mr--s@mobile-md adimo-container"
          >
            <Icon
              :icon="iconShoppingBag"
              :size="16"
            />
            {{ model.buyIngredientsLabel }}
          </button>

          <!-- Visually hidden textarea to copy content from -->
          <label
            class="u-hide-on-screenonly"
            for="clone"
          >
            <textarea
              id="clone"
              ref="clone"
              readonly
              :value="cloneText"
              @focus="$event.target.select()"
            />
          </label>
          <button
            class="c-button c-button--block-mobile u-mb--s"
            :disabled="isCopyButtonDisabled"
            @click="copyToClipboard"
          >
            <Icon
              :icon="iconClipboardCopy"
              :size="16"
              class="u-mr--xs"
            />
            {{ model.copyToClipboardLabel }}
          </button>
        </div>

        <div
          v-if="!model.adimoId"
          class="u-font-size-xlarge u-mt--s"
        >
          <a :href="model.whereToBuyPageUrl">
            {{ model.whereToBuyLabel }}
          </a>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import ingredientScaleHelper from '@/composition/recipeIngredientsScaleHelper'
import Modal from '@/components/Modal.vue'
import Icon from '@/components/Icon.vue'
import iconShare from '@/assets/img/icons/share.svg'
import iconClipboardCopy from '@/assets/img/icons/clipboard-copy.svg'
import iconShoppingBag from '@/assets/img/icons/shopping--bag.svg'
import iconCatalog from '@/assets/img/icons/catalog.svg'
import RecipeIngredientGroup from '@/components/recipe/RecipeIngredientGroup.vue'

export default {
  components: {
    Modal, Icon, RecipeIngredientGroup
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { state } = ingredientScaleHelper(props.model)
    const modal = ref()
    const clone = ref()
    const cloneText = ref('')
    const isCopyButtonDisabled = ref(false)

    const copyToClipboard = () => {
      isCopyButtonDisabled.value = true
      let stringToCopy = ''

      state.ingredientGroups.forEach((ingredientGroup) => {
        ingredientGroup.items.forEach((ingredient) => {
          const shoppingListLine = `${ingredient.name.ingredientUnitName} ${ingredient.name.ingredientName}`
          stringToCopy += `\n${shoppingListLine}`
        })
      })

      stringToCopy.slice(2) // Remove the first newline characters
      cloneText.value = stringToCopy
      setTimeout(() => {
        clone.value.focus()
        document.execCommand('copy')
        isCopyButtonDisabled.value = false
      }, 500)
    }

    return {
      portions: computed(() => state.portions),
      amountText: computed(() => state.amountText),
      scaledIngredientsGroups: computed(() => state.ingredientGroups),
      copyToClipboard,
      modal,
      clone,
      cloneText,
      isCopyButtonDisabled,
      iconShare,
      iconClipboardCopy,
      iconShoppingBag,
      iconCatalog
    }
  }
}
</script>
