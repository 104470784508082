<template>
  <div
    ref="searchWrapper"
    :class="{ 'sticky': isFiltersSticky }"
    class="c-search"
  >
    <div class="c-search__wrapper">
      <div
        v-if="hasExtendedFilters"
        class="c-search__wrapper__inner u-pt--s"
      >
        <button
          :class="{ 'active': isFiltersExpanded }"
          class="c-search__filters-toggle"
          @click="toggleFilters"
        >
          {{ translations.filtersText }}
          <Icon
            :icon="iconChevron"
            :size="24"
            class="u-ml--xs"
          />
        </button>

        <SearchInput />
      </div>

      <div
        ref="categoriesWrapper"
        class="c-search__filters"
      >
        <SearchCategories />
      </div>

      <SearchActiveTags />
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watchEffect
} from 'vue'
import Icon from '@/components/Icon.vue'
import iconChevron from '@/assets/img/icons/chevron--down.svg'
import SearchInput from '@/components/search/SearchInput.vue'
import SearchCategories from '@/components/search/SearchCategories.vue'
import SearchActiveTags from '@/components/search/SearchActiveTags.vue'
import searchConfig from '@/composition/search/searchConfig'
import searchQueryState from '@/composition/search/searchQuery'

export default {
  components: {
    Icon,
    SearchInput,
    SearchCategories,
    SearchActiveTags
  },
  setup() {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()
    const { hasExtendedFilters, activeCategories, activeTerms } = searchQueryState.searchQueryUse()

    const searchWrapper = ref(null)
    const categoriesWrapper = ref(null)
    const isFiltersExpanded = ref(true)
    const isFiltersSticky = ref(false)
    const isInitialCollapseSet = ref(false)
    let timeout = null

    const expand = () => {
      isFiltersExpanded.value = true
      categoriesWrapper.value.style.maxHeight = `${categoriesWrapper.value.scrollHeight}px`

      timeout = setTimeout(() => {
        categoriesWrapper.value.style.maxHeight = 'none'
      }, 200)
    }

    const collapse = () => {
      isFiltersExpanded.value = false
      categoriesWrapper.value.style.maxHeight = `${categoriesWrapper.value.scrollHeight}px`

      // Need to use a timeout to trigger the transition
      setTimeout(() => {
        categoriesWrapper.value.style.maxHeight = '0'
      })
    }

    // TODO: Consider creating a more flexible solution for expand/collapse functionality
    const toggleFilters = () => {
      clearTimeout(timeout)
      if (isFiltersExpanded.value) {
        collapse()
      } else {
        expand()
      }
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        isFiltersSticky.value = e.intersectionRatio < 1

        // Collapse filters when enabling sticky the first time only
        if (!isInitialCollapseSet.value && isFiltersSticky.value && e.boundingClientRect.y < 0) {
          isInitialCollapseSet.value = true
          collapse()
        }
      },
      { threshold: [1] }
    )
    onMounted(() => {
      if (hasExtendedFilters.value) {
        observer.observe(searchWrapper.value)
      }

      watchEffect(() => {
        if (
          hasExtendedFilters.value
          && (activeCategories.value.length || activeTerms.value.length)
        ) {
          collapse()
        } else if (categoriesWrapper.value) {
          expand()
        }
      })
    })

    return {
      hasExtendedFilters,
      searchWrapper,
      activeCategories,
      categoriesWrapper,
      isFiltersExpanded,
      isFiltersSticky,
      toggleFilters,
      iconChevron,
      translations: computed(() => searchConfiguration.translations)
    }
  }
}
</script>
