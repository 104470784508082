<template>
  <div
    class="c-fundamental-block u-bg-color--black u-text-white"
    :class="{ 'is-intro': slideIndex === 0}"
  >
    <div
      ref="mySlider"
      class="c-carousel c-fundamental-block-slider"
    >
      <div class="c-fundamental-block-slide u-text-align--center">
        <div class="c-fundamental-intro">
          <Picture
            v-if="model.image && model.image.src && !model.video"
            v-bind="model.image"
            full-width
            class="c-fundamental-intro-media"
          />
          <CVideo
            v-if="model.video"
            :src="model.video.src"
            :type="model.video.type"
            :poster="model.video.poster"
            autoplay
            loop
            muted
            class="c-fundamental-intro-media"
          />
          <div class="c-fundamental-intro-body u-width-text">
            <h6
              v-if="model.subtitle"
              class="u-mb--s u-font-regular"
            >
              {{ model.subtitle }}
            </h6>
            <div class="u-flex u-flex-justify-center">
              <h2
                v-if="model.title"
                class="c-fundamental-intro-heading u-mb--s u-width-70"
              >
                {{ model.title }}
              </h2>
            </div>
            <p
              v-if="model.text"
              class="c-fundamental-intro-description"
            >
              {{ model.text }}
            </p>
            <span
              role="button"
              class="c-button"
              aria-hidden="true"
              tabindex="0"
              @click="onHandleClick"
              @keydown.enter="onHandleClick"
            >
              {{ buttonText }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-for="(slide, index) in model.mediaStepsItems"
        :key="index"
        class="c-fundamental-block-slider-slide"
      >
        <div class="c-fundamental-box">
          <div class="c-fundamental-box-inner">
            <div class="c-fundamental-box-body">
              <Picture
                v-if="slide.image && slide.image.src && !slide.video"
                v-bind="slide.image"
                full-width
                class="c-fundamental-box-media"
              />
              <CVideo
                v-if="slide.video"
                :src="slide.video.src"
                :heading="slide.video.heading"
                :type="slide.video.type"
                :poster="slide.poster"
                autoplay
                loop
                muted
                class="c-fundamental-box-media"
              />
              <div class="c-fundamental-box-content o-content-scrollbar">
                <h5
                  v-if="slide.title"
                >
                  {{ slide.title }}
                </h5>
                <p
                  v-if="slide.text"
                >
                  {{ slide.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-carousel__controls c-carousel__controls--white">
      <div
        ref="mySliderControls"
        class="c-carousel__navigation u-flex u-flex-align-center"
        aria-label="Carousel Navigation"
        tabindex="0"
      >
        <button
          data-button="fundamental-carousel-prev"
          data-controls="prev"
          aria-controls="customize"
          tabindex="-1"
          class="c-carousel__navigation-button c-carousel__navigation-button-prev"
          @click="onPrevClick"
        >
          <Icon
            :icon="iconChevronRight"
            :size="20"
            class="c-carousel__button"
          />
        </button>
        <button
          data-button="fundamental-carousel-next"
          data-controls="next"
          aria-controls="customize"
          tabindex="-1"
          class="c-carousel__navigation-button c-carousel__navigation-button-next"
          @click="onNextClick"
        >
          <Icon
            :icon="iconChevronRight"
            :size="20"
            class="c-carousel__button"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { fundamentalSlider } from '@/composition/slider'
import Picture from '@/components/Picture.vue'
import CVideo from '@/components/Video.vue'
import gtmUtil from '@/utils/gtm-util'

import Icon from '@/components/Icon.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'

export default {
  components: {
    Picture,
    CVideo,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  setup() {
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const slideIndex = ref(0)

    const onHandleClick = () => {
      document.querySelector('[data-button="fundamental-carousel-next"]').click()
      gtmUtil.fundamentalsStart()
    }

    const onPrevClick = () => {
      gtmUtil.fundamentalsPreviousSlide()
    }

    const onNextClick = () => {
      gtmUtil.fundamentalsNextSlide()
    }

    const onIndexChange = (e) => {
      slideIndex.value = e.index
    }

    onMounted(() => {
      fundamentalSlider(mySlider.value, mySliderControls.value, onIndexChange)
    })

    return {
      iconChevronRight,
      mySlider,
      mySliderControls,
      slideIndex,
      onHandleClick,
      onPrevClick,
      onNextClick
    }
  }
}
</script>
