<template>
  <button
    class="c-button--icon c-header__share u-flex"
    @click="onPrintClick"
  >
    <Icon
      :icon="printIcon"
      :size="24"
      class="c-header__icon--print u-ml--s u-mr--s"
    />
    <span
      v-if="model.staticText"
      class="c-header__share-text u-font-size-small"
    >
      {{ model.staticText }}
    </span>
  </button>
</template>

<script>
import printIcon from '@/assets/img/icons/print.svg'
import Icon from '@/components/Icon.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          staticText: ''
        }
      }
    }
  },
  setup() {
    const onPrintClick = () => {
      window.print()
      gtmUtil.recipePrint()
    }

    return {
      printIcon,
      onPrintClick
    }
  }
}
</script>
