<template>
  <li
    :class="{'expanded' : isExpanded || isAnimating }"
    class="c-accordion-item"
  >
    <h3
      v-if="model.header"
      class="c-accordion-heading"
    >
      <button
        class="c-accordion-heading-toggler"
        @click="toggle"
      >
        {{ model.header }}
        <Icon
          :icon="iconChevron"
          :size="16"
          class="c-accordion-heading-icon"
        />
      </button>
    </h3>
    <div
      v-show="isExpanded || isAnimating"
      ref="content"
      class="c-accordion-body-text"
      :style="showStyles ? contentStyle : ''"
      v-html="model.text"
    />
  </li>
</template>

<script>
import Icon from '@/components/Icon.vue'
import iconChevron from '@/assets/img/icons/chevron--down.svg'
import gtmUtil from '@/utils/gtm-util'
import expandCollapse from '@/utils/expand-collapse'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    minHeight: {
      type: Number,
      default: 0
    },
    isExpandedByDefault: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    expandCollapse
  ],
  setup(props) {
    const { header } = props.model
    const trackExpand = () => {
      gtmUtil.accordionOpen(header)
    }
    const trackCollapse = () => {
      gtmUtil.accordionClose(header)
    }

    return {
      iconChevron,

      trackExpand,
      trackCollapse
    }
  }
}
</script>
