<template>
  <div
    v-if="model"
    class="c-carousel-wrapper"
  >
    <div
      ref="mySlider"
      class="c-carousel c-carousel-standard"
      :class="[displaySlider, alignedSlides]"
    >
      <div
        v-for="(card, index) in model"
        :key="index"
        class="c-carousel__slide"
        :class="{ 'c-carousel__slide--single': cardCount === 1 }"
      >
        <StandardCard
          :index="index"
          :model="card"
        />
      </div>
    </div>
    <div
      ref="mySliderControls"
      class="c-carousel__controls"
    >
      <SliderControls
        @onNextClick="onSlideClick('right')"
        @onPreviousClick="onSlideClick('left')"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useSlider, useSlidesConfig } from '@/composition/slider'

import StandardCard from '@/components/blocks/StandardCard.vue'
import SliderControls from '@/components/navigation/SliderControls.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    SliderControls,
    StandardCard
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    initGrid: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const mySlider = ref(null)
    const mySliderControls = ref(null)

    const cardCount = computed(() => props.model.length)

    onMounted(() => {
      useSlider({
        disable: props.initGrid
      }, mySlider.value, mySliderControls.value.children[0])
    })

    const onSlideClick = (direction) => {
      gtmUtil.standardSliderSlide(direction)
    }

    return {
      cardCount,
      mySlider,
      mySliderControls,
      onSlideClick,
      ...useSlidesConfig(props, 3)
    }
  }
}
</script>
