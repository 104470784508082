<template>
  <div class="c-recipe-question">
    <div class="u-font-size-large u-font-bold u-text-uppercase u-mb--s">
      {{ model.title }}
    </div>

    <div
      v-if="isAnswered"
      class="c-recipe-question__success-message u-flex u-flex-align-center"
    >
      <Icon
        :icon="iconCheckmark"
        :size="16"
        class="u-mr--xs"
      />
      {{ model.successMessage }}
    </div>

    <div
      v-else
      class="c-recipe-question__buttons u-flex u-font-size-large"
    >
      <button
        class="c-button"
        @click="isAnswered = !isAnswered"
      >
        <Icon
          :icon="iconThumbsUp"
          :size="16"
        />
        {{ model.answerGood }}
      </button>
      <button
        class="c-button"
        @click="isAnswered = !isAnswered"
      >
        <Icon
          :icon="iconThumbsDown"
          :size="16"
        />
        {{ model.answerBad }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Icon from '@/components/Icon.vue'
import iconCheckmark from '@/assets/img/icons/checkmark--outline.svg'
import iconThumbsUp from '@/assets/img/icons/thumbs-up.svg'
import iconThumbsDown from '@/assets/img/icons/thumbs-down.svg'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    const isAnswered = ref(false)

    return {
      isAnswered,
      iconCheckmark,
      iconThumbsUp,
      iconThumbsDown
    }
  }
}
</script>
