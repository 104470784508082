<template>
  <div
    class="c-carousel-wrapper"
    :class="alignedSlides"
  >
    <div
      ref="mySlider"
      class="c-carousel c-carousel-circle"
      :class="{ 'center-slides': centerSlides }"
    >
      <div
        v-for="(card, index) in model"
        :key="index"
      >
        <CircleCard
          :index="index"
          :model="card"
        />
      </div>
    </div>
    <div
      ref="mySliderControls"
      class="c-carousel__controls"
    >
      <SliderControls
        @onNextClick="onSlideClick('right')"
        @onPreviousClick="onSlideClick('left')"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useCircleSlider, useSlidesConfig } from '@/composition/slider'

import CircleCard from '@/components/blocks/CircleCard.vue'
import SliderControls from '@/components/navigation/SliderControls.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    SliderControls,
    CircleCard
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    initGrid: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const centerSlides = ref(false)
    const slider = ref(null)
    const { alignedSlides } = useSlidesConfig(props, 4)

    const checkSlidesNumber = () => {
      const { slideCount, items } = slider.value.getInfo()
      centerSlides.value = items > slideCount
    }

    onMounted(() => {
      slider.value = useCircleSlider(mySlider.value, mySliderControls.value.children[0])
      if (slider.value) {
        checkSlidesNumber()
        window.addEventListener('resize', checkSlidesNumber)
      }
    })

    onBeforeUnmount(() => {
      if (slider.value) {
        window.removeEventListener('resize', checkSlidesNumber)
      }
    })

    const onSlideClick = (direction) => {
      gtmUtil.circleSliderSlide(direction)
    }

    return {
      mySlider,
      mySliderControls,
      alignedSlides,
      centerSlides,

      onSlideClick
    }
  }
}
</script>
