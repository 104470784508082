<template>
  <h3
    v-if="showHeading"
    class="c-recipe-ingredients__list__heading u-font-size-large"
  >
    {{ model.name }}
  </h3>
  <recipe-ingredient-item
    v-for="ingredient in model.items"
    :key="ingredient.id"
    :model="ingredient"
  />
</template>

<script>
import { computed } from 'vue'
import RecipeIngredientItem from '@/components/recipe/RecipeIngredientItem.vue'

export default {
  components: {
    RecipeIngredientItem
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showGroupName: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    return {
      showHeading: computed(() => props.showGroupName && props.model.name)
    }
  }
}
</script>
