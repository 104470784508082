<template>
  <div
    v-if="model.items"
    class="c-featured-content u-pt--m u-pb--m u-flex-direction-column body-scroll-lock-ignore"
  >
    <div class="c-featured-carousel-heading-holder u-flex u-flex-align-center u-pl--s u-mb--m">
      <h6 class="c-featured-content-heading u-mr--s">
        {{ model.title }}
      </h6>
      <div class="c-featured-carousel-arrows u-flex u-flex-align-center">
        <div
          ref="mySliderControls"
          class="c-carousel__navigation text-white u-flex u-flex-align-center"
          aria-label="Carousel Navigation"
          tabindex="0"
        >
          <button
            data-controls="prev"
            aria-controls="customize"
            tabindex="-1"
            class="c-carousel__navigation-button c-carousel__navigation-button--sm c-carousel__navigation-button-prev"
          >
            <Icon
              :icon="iconChevronRight"
              :size="20"
              class="c-carousel__button"
            />
          </button>
          <button
            data-controls="next"
            aria-controls="customize"
            tabindex="-1"
            class="c-carousel__navigation-button c-carousel__navigation-button--sm c-carousel__navigation-button-next"
          >
            <Icon
              :icon="iconChevronRight"
              :size="20"
              class="c-carousel__button"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      ref="mySlider"
      class="c-carousel c-feature-carousel"
    >
      <div
        v-for="(item, index) in model.items"
        :key="index"
      >
        <div class="c-feature">
          <Link
            v-if="item.link.url"
            :model="item.link"
            class="c-feature__link"
          >
            <Picture
              v-if="item.image"
              v-bind="item.image"
              class="c-feature__image"
              :mobile="[120,]"
              :tablet="[120,]"
              :desktop="[120,]"
              :wide="[120,]"
            />
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import { useFeatureSlider } from '@/composition/slider'
import Picture from '@/components/Picture.vue'
import Link from '@/components/Link.vue'
import Icon from '@/components/Icon.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'

export default {
  components: {
    Link,
    Picture,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    const mySlider = ref(null)
    const mySliderControls = ref(null)

    onMounted(() => {
      useFeatureSlider({
        items: 2.5
      }, mySlider.value, mySliderControls.value)
    })

    return {
      iconChevronRight,
      mySlider,
      mySliderControls
    }
  }
}
</script>
