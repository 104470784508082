<template>
  <div
    id="recaptcha"
    data-size="invisible"
  />
</template>
<script>

export default {
  props: {
    recaptchaKey: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const getRecaptchaResponse = (response) => {
      if (response) {
        context.emit('submit', response)
      }
    }

    window.renderCaptcha = () => {
      // eslint-disable-next-line no-undef
      if (grecaptcha) {
        // eslint-disable-next-line no-undef
        grecaptcha.render('recaptcha', {
          sitekey: props.recaptchaKey,
          callback: getRecaptchaResponse
        })
      }
    }

    function verify() {
      // eslint-disable-next-line no-undef
      if (grecaptcha) {
        // eslint-disable-next-line no-undef
        const response = grecaptcha.getResponse()
        if (response.length === 0) {
          // eslint-disable-next-line no-undef
          grecaptcha.execute()
        } else {
          context.emit('submit', response)
        }
      }
    }

    return {
      verify
    }
  }
}
</script>
