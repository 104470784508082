<template>
  <transition name="recipe-navigation">
    <div
      v-if="showNavigation"
      ref="recipeNavigation"
      :style="navigationStyles"
      class="c-recipe-navigation c-recipe-navigation--desktop"
    >
      <div class="c-recipe-navigation__recipe-title c-recipe-navigation__recipe-title__desktop">
        {{ model.recipeName }}
        <div class="c-recipe-navigation__buttons u-flex">
          <PrintButton
            class="u-mr--s"
            :model="model.printButton"
          />
          <ShareButton :model="model.shareButton" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  onMounted, onUnmounted, ref, computed
} from 'vue'
import ShareButton from '@/components/navigation/ShareButton.vue'
import PrintButton from '@/components/navigation/PrintButton.vue'

export default {
  components: {
    ShareButton,
    PrintButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    triggerElementSelector: {
      type: String,
      default: '.c-item-hero'
    }
  },
  setup(props) {
    const recipeNavigation = ref(null)
    const showNavigation = ref(false)
    const navigationStyles = computed(() => (props.model.backgroundImage?.src
      ? `background-image: url('${props.model.backgroundImage.src}')`
      : ''))

    const handleScroll = () => {
      const scrollTarget = document.querySelector(props.triggerElementSelector)
      if (window.scrollY > (scrollTarget.offsetTop + scrollTarget.offsetHeight)) {
        if (!showNavigation.value) showNavigation.value = true
      } else if (showNavigation.value) showNavigation.value = false
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      recipeNavigation,
      showNavigation,
      navigationStyles
    }
  }
}
</script>
