<template>
  <picture>
    <source
      v-if="extraWideValue"
      media="(min-width: 1600px)"
      :srcset="srcset(src, extraWideValue)"
    >
    <source
      v-if="wideValue"
      media="(min-width: 1200px)"
      :srcset="srcset(src, wideValue)"
    >
    <source
      v-if="desktopValue"
      media="(min-width: 992px)"
      :srcset="srcset(src, desktopValue)"
    >
    <source
      v-if="tabletValue"
      media="(min-width: 768px)"
      :srcset="srcset(mobileSrcValue, tabletValue, true)"
    >
    <img
      v-if="mobile"
      :class="imgClass"
      :src="resize(mobileSrcValue, mobile)"
      :srcset="srcset(mobileSrcValue, mobile, true)"
      :alt="alt"
    >
    <img
      v-else
      :class="imgClass"
      :src="mobileSrcUrl"
      :alt="alt"
    >
    <slot />
  </picture>
</template>

<script>
import imageResizer from '@/utils/image-resizer'

export default {
  props: {
    src: {
      type: [Object, String],
      required: true
    },
    mobileSrc: {
      type: [Object, String],
      default: null
    },
    imgClass: {
      type: String,
      default: null
    },
    mobile: {
      type: Array,
      default: () => [375, 0]
    },
    tablet: {
      type: Array,
      default: null
    },
    desktop: {
      type: Array,
      default: null
    },
    wide: {
      type: Array,
      default: null
    },
    extraWide: {
      type: Array,
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    },
    imageResizer: {
      type: Object,
      default: () => ({ name: 'ImageResizingNet' })
    },
    alt: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const mobileSrcValue = props.mobileSrc ?? props.src
    let tabletValue = props.tablet
    let desktopValue = props.desktop
    let wideValue = props.wide
    let extraWideValue = props.extraWide
    const mobileSrcUrl = mobileSrcValue?.url || mobileSrcValue

    if (props.fullWidth) {
      tabletValue ??= [720, 0]
      desktopValue ??= [960, 0]
      wideValue ??= [1500, 0]
      extraWideValue ??= [1920, 0]
    }

    const resize = (src, sizes) => {
      const resizer = imageResizer[props.imageResizer.name]
      return resizer
        .resize(src, sizes[0], sizes[1], props.options)
    }

    const srcset = (src, sizes, add2x = false) => {
      const defaultSize = resize(src, sizes)

      if (!add2x) {
        return defaultSize
      }

      const multipliedSize = resize(src, [sizes[0] * 2, sizes[1] * 2])
      return `${defaultSize}, ${multipliedSize} 2x`
    }

    return {
      mobileSrcValue,
      tabletValue,
      desktopValue,
      wideValue,
      extraWideValue,
      mobileSrcUrl,
      resize,
      srcset
    }
  }
}
</script>
