<template>
  <span
    ref="root"
    class="fit"
  >
    <slot />
  </span>
</template>

<script>
import fitty from 'fitty'
import {
  onMounted,
  onUnmounted,
  ref
} from 'vue'

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {
          minSize: 16,
          maxSize: 512,
          multiLine: false
        }
      }
    }
  },
  setup(props) {
    const root = ref(null)
    let fittyObj = ref(undefined)

    onMounted(() => {
      setTimeout(() => {
        fittyObj = fitty(root.value, props.options)
      }, 300)
    })

    onUnmounted(() => {
      fittyObj.unsubscribe()
    })

    return {
      root,
      fittyObj
    }
  }
}
</script>
