<template>
  <div
    v-if="isScreenLockSupported"
    class="c-recipe__cook-mode u-mb--m"
  >
    <div class="u-mb--xs">
      {{ model.title }}
    </div>
    <button
      class="c-button c-button--no-styles u-flex u-flex-align-center"
      @click="toggle"
    >
      <span
        class="c-toggler c-toggler--gray u-flex-shrink-0 u-mr--s"
        :class="{
          'is-selected': isSelected
        }"
      />
      <span class="u-font-size-xlarge u-font-normal">
        {{ model.text }}
      </span>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isSelected: false,
      screenLock: null,
      isScreenLockSupported: 'wakeLock' in window.navigator
    }
  },
  methods: {
    toggle() {
      if (!this.isSelected) {
        this.initScreenLock()
        this.isSelected = true
        window.dataLayer.push({
          event: 'cookToggle',
          recipeTitle: window.gtmData.recipeName,
          action: 'enable'
        })
      } else {
        this.removeScreenLock()
        this.isSelected = false
        window.dataLayer.push({
          event: 'cookToggle',
          recipeTitle: window.gtmData.recipeName,
          action: 'disable'
        })
      }
    },
    async initScreenLock() {
      try {
        this.screenLock = await navigator.wakeLock.request('screen')
        this.screenLock.addEventListener('release', () => {
          this.isSelected = false
        })
      } catch (err) {
        console.log(err.name, err.message)
      }
    },
    async removeScreenLock() {
      await this.screenLock.release()
      this.screenLock = null
    }
  }
}
</script>
