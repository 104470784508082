/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import gtmUtil from '@/utils/gtm-util'

class FormHandler {
  init() {
    if (typeof $$epiforms !== 'undefined') {
      $$epiforms(document).ready(() => {
        $$epiforms('.EPiServerForms').on('formsSubmitted', (e) => {
          if (e.isFinalizedSubmission && e.isSuccess) {
            gtmUtil.formSubmitted()
          }
        })
      })
    }
  }
}

export default new FormHandler()
