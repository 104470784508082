<template>
  <div class="u-mb--s">
    <div
      v-if="model.name.ingredientUnitName"
      class="c-recipe-ingredients__list-cell--sm"
    >
      {{ model.name.ingredientUnitName }}
    </div>
    <div
      class="c-recipe-ingredients__list-cell--lg"
      :class="{'u-text-capitalize-first-letter': !model.name.ingredientUnitName}"
    >
      <a
        v-if="model.product"
        :href="model.product.url"
      >
        {{ ingredientName }}
      </a>
      <span v-else>{{ ingredientName }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      ingredientName: computed(() => (
        props.model.secondaryUnit
          ? `${props.model.name.ingredientName} (${props.model.secondaryUnit})`
          : props.model.name.ingredientName))

    }
  }
}
</script>
