<template>
  <div class="c-product-list">
    <article
      v-for="(item, index) in items"
      :key="index"
      class="c-product-list__item"
      :class="{ 'c-product-list__item--full-width': !item.uid }"
    >
      <ProductCard
        v-if="item.uid"
        :model="item"
      />

      <FullWidthBlock
        v-else
        :model="item"
      />
    </article>
  </div>
</template>

<script>
import ProductCard from '@/components/blocks/ProductCard.vue'
import FullWidthBlock from '@/components/blocks/FullWidthBlock.vue'

export default {
  components: {
    ProductCard, FullWidthBlock
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
