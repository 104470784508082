<template>
  <div class="c-standard-card u-text-align--center">
    <div class="c-standard-card__image-wrapper">
      <Picture
        v-if="model.image"
        v-bind="model.image"
        :mobile="[270,]"
        :tablet="[220,]"
        :desktop="[260,]"
        :wide="[323,]"
        class="c-standard-card__image u-overlay--card"
      />

      <a
        v-if="model.url"
        :href="model.url"
        class="c-standard-card__link u-text-white u-flex u-flex-direction-column u-flex-justify-end u-pl--s u-pr--s u-pb--m"
      >
        <p
          v-if="model.subtitle"
          class="c-standard-card__subtitle u-mb--xxs"
        >
          {{ model.subtitle }}
        </p>
        <h3
          v-if="model.title"
          class="c-standard-card__heading u-mb--xs"
        >
          {{ model.title }}
        </h3>
        <p
          v-if="model.text"
          class="c-standard-card__description"
        >
          {{ model.text }}
        </p>
      </a>
      <div
        v-else
        class="c-standard-card__body u-text-white u-flex u-flex-direction-column u-flex-justify-end u-pl--s u-pr--s u-pb--m"
      >
        <p
          v-if="model.subtitle"
          class="c-standard-card__subtitle u-mb--xxs"
        >
          {{ model.subtitle }}
        </p>
        <h2
          v-if="model.title"
          class="c-standard-card__heading u-mb--xs"
        >
          {{ model.title }}
        </h2>
        <p
          v-if="model.text"
          class="c-standard-card__description"
        >
          {{ model.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
