<template>
  <div :class="cssClass">
    <div class="c-split-block-container u-width-max">
      <div class="c-split-block__heading-container u-text-align--center">
        <h6
          v-if="model && model.subtitle"
          class="u-font-regular u-mb--xs"
        >
          {{ model.subtitle }}
        </h6>
        <h3
          v-if="model && model.title"
          class="u-mb--s"
        >
          {{ model.title }}
        </h3>
      </div>
      <div class="c-split-block__media">
        <Picture
          v-if="model && model.image && !model.video"
          v-bind="model.image"
          :mobile="[390,]"
          :tablet="[526,]"
          :desktop="[540,]"
          :wide="[720,]"
          class="c-split-block__image"
        />
        <CVideo
          v-if="model && model.video"
          :src="model.video.src"
          :type="model.video.type"
          :poster="model.image.src"
          :heading="model.video.heading"
          show-btn-play
          controls
          class="c-split-block__image"
        />
      </div>
      <div class="c-split-block__content-container u-text-align--center">
        <p class="e-description u-mt--0">
          {{ model && model.description }}
        </p>
        <Link
          v-if="model && model.link.url"
          :model="model.link"
          class="c-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/Link.vue'
import Picture from '@/components/Picture.vue'
import CVideo from '@/components/Video.vue'

export default {
  components: {
    Link,
    Picture,
    CVideo
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    theme: {
      type: String,
      required: true
    },
    alignedContent: {
      type: String,
      required: true
    }
  },
  computed: {
    themeClass() {
      if (this.theme === 'black') {
        return 'u-bg-color--black u-text-white'
      }

      if (this.theme === 'grey') {
        return 'u-bg-color--off-white'
      }
      return 'u-bg-color--white u-text-black'
    },
    alignedContentClass() {
      return this.alignedContent === 'right'
        ? 'c-split-block--right-content'
        : 'c-split-block--left-content'
    },
    cssClass() {
      return [
        'c-split-block o-content-box',
        this.themeClass,
        this.alignedContentClass
      ]
    }
  }
}
</script>
