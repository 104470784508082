<template>
  <form
    class="u-flex-grow-1 u-ml--s"
    @submit.prevent="onSearchSubmit"
  >
    <label
      class="c-search__label"
      for="search"
    >
      <input
        id="search"
        v-model="searchTerm"
        :aria-label="translations.searchPlaceholderText"
        :placeholder="translations.searchPlaceholderText"
        class="c-search__input"
        name="search"
        type="search"
      >

      <button
        v-if="showClearButton"
        class="c-search__button-clear"
        type="button"
        @click="searchTerm = ''"
      >
        <Icon
          :icon="iconClose"
          :size="16"
        />
      </button>
      <button
        class="c-search__button-submit"
        type="submit"
      >
        <Icon
          :icon="iconSearch"
          :size="24"
        />
      </button>
    </label>
  </form>
</template>

<script>
import { computed, ref, watchEffect } from 'vue'
import Icon from '@/components/Icon.vue'
import iconSearch from '@/assets/img/icons/search.svg'
import iconClose from '@/assets/img/icons/close.svg'
import searchConfig from '@/composition/search/searchConfig'
import searchQuery from '@/composition/search/searchQuery'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: { Icon },
  setup() {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()
    const { addCategory, addTerm } = searchQuery.searchQueryUse()

    const searchTerm = ref('')
    const showClearButton = ref(false)
    const trackStartTyping = ref(false)

    watchEffect(() => {
      showClearButton.value = !!searchTerm.value
      if (showClearButton.value && !trackStartTyping.value) {
        trackStartTyping.value = true
        gtmUtil.startSearchTyping()
      }
    })

    const onSearchSubmit = () => {
      if (searchTerm.value) {
        const termAsCategory = searchConfiguration.categories
          .filter((obj) => obj.name.toLowerCase() === searchTerm.value.toLowerCase())

        if (termAsCategory.length) {
          addCategory(termAsCategory[0])
        } else {
          addTerm(searchTerm.value)
        }
        gtmUtil.searchTerm(searchTerm.value)
        searchTerm.value = ''
      }
    }

    return {
      searchTerm,
      showClearButton,
      onSearchSubmit,
      iconSearch,
      iconClose,
      translations: computed(() => searchConfiguration.translations)
    }
  }
}
</script>
