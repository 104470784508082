<template>
  <div>
    <FundamentalBlock
      v-if="isReady"
      :model="model"
      :button-text="buttonText"
    />
  </div>
</template>

<script>

import { ref, defineAsyncComponent } from 'vue'

export default {
  components: {
    FundamentalBlock: defineAsyncComponent(() => import('./FundamentalBlock.vue'))
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  setup() {
    const isReady = ref(null)
    isReady.value = true

    return {
      isReady
    }
  }
}
</script>
