<template>
  <div class="c-recipe-ingredients">
    <h2>{{ model.ingredientsHeading }}</h2>

    <!-- Portion selector -->
    <template v-if="portions">
      <span
        v-if="!model.ingredients.amountIsScalable"
        class="c-recipe-ingredients__portion--fixed"
      >
        {{ portions }} {{ amountText }}
      </span>

      <div
        v-else
        class="c-recipe-ingredients__portions"
      >
        <button
          class="c-recipe-ingredients__portions-button"
          data-gtm-trigger="click"
          @click="performStep('down')"
        >
          <Icon
            :icon="iconSubtract"
            :size="20"
          />
        </button>
        <div class="c-recipe-ingredients__portions-label">
          {{ portions }} {{ amountText }}
        </div>
        <button
          class="c-recipe-ingredients__portions-button"
          data-gtm-trigger="click"
          @click="performStep('up')"
        >
          <Icon
            :icon="iconAdd"
            :size="20"
          />
        </button>
      </div>
    </template>

    <!-- Ingredient list -->
    <div class="c-recipe-ingredients__list">
      <recipe-ingredient-group
        v-for="ingredientGroup in scaledIngredientsGroups"
        :key="ingredientGroup.index"
        :model="ingredientGroup"
      />
    </div>

    <!-- Actions -->
    <RecipeActions :model="model" />
  </div>
</template>

<script>
import { computed } from 'vue'
import RecipeActions from '@/components/recipe/RecipeActions.vue'
import ingredientScaleHelper from '@/composition/recipeIngredientsScaleHelper'
import RecipeIngredientGroup from '@/components/recipe/RecipeIngredientGroup.vue'
import Icon from '@/components/Icon.vue'
import iconAdd from '@/assets/img/icons/add.svg'
import iconSubtract from '@/assets/img/icons/subtract.svg'

export default {
  components: {
    RecipeActions, Icon, RecipeIngredientGroup
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { setPortions, state } = ingredientScaleHelper(props.model)

    const minValue = 1
    const maxValue = state.basePortions * 10
    const stepValue = 1

    function performStep(action) {
      let newValue
      if (action === 'up') {
        newValue = Math.floor((state.portions + stepValue) / stepValue) * stepValue
      } else {
        newValue = Math.ceil((state.portions - stepValue) / stepValue) * stepValue
      }

      if (action === 'up' && newValue <= maxValue) {
        setPortions(Math.floor((state.portions + stepValue) / stepValue) * stepValue)
      } else if (action === 'down' && newValue >= minValue) {
        setPortions(Math.ceil((state.portions - stepValue) / stepValue) * stepValue)
      }
    }

    return {
      minValue,
      maxValue,
      stepValue,
      portions: computed(() => state.portions),
      amountText: computed(() => state.amountText),
      scaledIngredientsGroups: computed(() => state.ingredientGroups),
      performStep,
      iconAdd,
      iconSubtract
    }
  }
}
</script>
