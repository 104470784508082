<template>
  <svg :class="cssClass">
    <use :xlink:href="icon.symbol" />
  </svg>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    icon: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    return {
      cssClass: computed(() => [
        props.size ? `c-icon-${props.size}` : ''
      ])
    }
  }
}
</script>
