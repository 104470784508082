<template>
  <button
    class="c-button--icon c-header__share u-flex"
    @click="onShareClick"
  >
    <Icon
      :icon="shareIcon"
      :size="24"
      class="c-header__icon--share u-mr--s"
    />
    <span
      v-if="model.staticText && !state.copied"
      class="c-header__share-text u-font-size-small"
    >
      {{ model.staticText }}
    </span>
    <span
      v-if="model.clickText && state.copied"
      class="c-header__share-text u-font-size-small"
    >
      {{ model.clickText }}
    </span>
  </button>
</template>

<script>
import { useMq } from 'vue3-mq'
import shareIcon from '@/assets/img/icons/share.svg'
import Icon from '@/components/Icon.vue'
import { state, setCopied } from '@/composition/shareButton'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          customShareUrl: '',
          staticText: ''
        }
      }
    }
  },
  setup(props) {
    const isMobile = useMq().current === 'mobile' || useMq().current === 'tablet'

    const copyComplete = () => {
      setCopied(true)
      gtmUtil.recipeShare()
    }

    const copyUrl = (url) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(url).then(() => {
          copyComplete()
        }).catch()
      } else {
        const dummy = document.createElement('input')
        document.body.appendChild(dummy)
        dummy.value = url
        dummy.select()
        dummy.focus()
        document.execCommand('copy')
        document.body.removeChild(dummy)
        copyComplete()
      }
    }

    const shareUrl = (data) => {
      if (isMobile) {
        navigator.share(data).then(() => {
          gtmUtil.recipeShare()
        }).catch()
      } else {
        copyUrl(data.url)
      }
    }

    const onShareClick = () => {
      if (props.model.customShareUrl) {
        shareUrl({
          url: props.model.customShareUrl
        })
        return
      }
      shareUrl({
        title: document.title,
        url: window.location.href
      })
    }

    return {
      isMobile,
      shareIcon,
      state,
      onShareClick,
      shareUrl,
      copyUrl
    }
  }
}
</script>
