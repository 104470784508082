<template>
  <div class="c-scale-heading u-mx--a">
    <component
      :is="tag"
      v-if="lines.length"
    >
      <fit-text
        v-for="(line, index) in lines"
        :key="index"
      >
        {{ line }}
      </fit-text>
    </component>
    <component
      :is="tag"
      v-else
    >
      <fit-text>
        <slot />
      </fit-text>
    </component>
  </div>
</template>

<script>
import FitText from '@/utils/FitText.vue'

export default {
  components: {
    FitText
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: () => 'h2'
    },
    lines: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
