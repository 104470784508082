<template>
  <div class="c-media-card u-text-align--center">
    <div class="c-media-card-image-wrapper">
      <Picture
        v-if="model.image"
        v-bind="model.image"
        class="c-media-card-image"
      >
        <button
          ref="btnPlay"
          class="c-media-card-image-button-play"
          @click="$emit('on-handle-click', {
            modalModel: model.modal
          })"
        >
          <Icon
            :icon="iconCaretRight"
            :size="32"
          />
        </button>
      </Picture>
    </div>
    <div
      v-if="showLink"
      class="c-media-card-body u-pl--s u-pr--s"
    >
      <a
        :href="model.url"
        class="c-media-card-heading"
      >
        {{ model.title }}
      </a>
    </div>
  </div>
</template>

<script>

import Picture from '@/components/Picture.vue'
import Icon from '@/components/Icon.vue'
import iconCaretRight from '@/assets/img/icons/caret--right.svg'

export default {
  components: {
    Picture,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showLink: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return { iconCaretRight }
  }
}
</script>
