<template>
  <div
    v-if="activeTerms.length || activeCategories.length"
    class="c-search__filter-list"
  >
    <button
      v-for="(term, index) in activeTerms"
      :key="index"
      class="c-button c-button--filter-solid"
      @click="removeSearchTerm(term)"
    >
      {{ term }}
      <Icon
        :icon="iconClose"
        :size="24"
      />
    </button>

    <button
      v-for="(category, index) in activeCategories"
      :key="index"
      class="c-button c-button--filter-solid"
      @click="removeFilter(category)"
    >
      {{ category.name }}
      <Icon
        :icon="iconClose"
        :size="24"
      />
    </button>

    <button
      class="c-button-link u-ml--s"
      @click="removeAllFilters"
    >
      {{ translations.clearAllText }}
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import iconClose from '@/assets/img/icons/close.svg'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: { Icon },
  setup() {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const {
      activeTerms,
      activeCategories,
      removeTerm,
      removeCategory,
      resetAllFilters
    } = searchQueryState.searchQueryUse()

    const removeFilter = (filter) => {
      removeCategory(filter)
      gtmUtil.removeFilter(filter.name)
    }

    const removeSearchTerm = (term) => {
      removeTerm(term)
      gtmUtil.removeFilter(term)
    }

    const removeAllFilters = () => {
      resetAllFilters()
      gtmUtil.removeAllFilters()
    }

    return {
      iconClose,
      activeTerms,
      removeSearchTerm,
      activeCategories,
      removeFilter,
      removeAllFilters,
      translations: computed(() => searchConfiguration.translations)
    }
  }
}
</script>
