<template>
  <ul class="c-accordion u-bare-list">
    <accordion-item
      v-for="(item, index) in items"
      :key="index"
      :model="item"
    />
  </ul>
</template>

<script>
import AccordionItem from '@/components/AccordionItem.vue'

export default {
  components: {
    AccordionItem
  },
  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  }
}
</script>
