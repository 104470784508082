<template>
  <div>
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="token"
    >
    <Recaptcha
      ref="recaptcha"
      :recaptcha-key="model.recaptchaKey"
      @submit="recaptchaSubmit"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Recaptcha from '@/components/Recaptcha.vue'

export default {
  components: {
    Recaptcha
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const token = ref('')
    const recaptcha = ref(null)
    const recaptchaSubmit = async (tokenValue) => {
      token.value = tokenValue
      const form = document.getElementById(props.model.formGuid)
      const submitButton = form.querySelector('[type=submit]')
      submitButton.click()
    }
    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
      if (!forms) {
        return
      }
      // eslint-disable-next-line no-undef
      jQuery.extend(true, forms, {
        Validators: {
          'Website.Features.Blocks.Components.InvisibleRecaptchaElementBlockComponent.InvisibleRecaptchaValidator': () => {
            const isValid = !!token.value
            if (!isValid && recaptcha.value) {
              recaptcha.value.verify()
            }
            return { isValid }
          }
        }
      })
    })
    return {
      token,
      recaptcha,
      recaptchaSubmit
    }
  }
}
</script>
