export default {
  data() {
    return {
      isExpanded: this.isExpandedByDefault,
      showStyles: !this.isExpandedByDefault,
      isAnimating: false,
      contentStyle: {
        overflow: 'hidden',
        height: `${this.minHeight}px`
      }
    }
  },
  methods: {
    toggle() {
      if (this.isExpanded && !this.isAnimating) {
        this.collapse()
      } else {
        this.expand()
      }
    },
    expand() {
      const { content } = this.$refs

      this.showStyles = true
      this.isAnimating = true

      const listener = () => {
        this.isAnimating = false
        this.isExpanded = true
        this.showStyles = false

        content.removeEventListener('transitionend', listener, false)
      }

      content.addEventListener('transitionend', listener, false)

      setTimeout(() => {
        this.contentStyle.height = `${content.scrollHeight}px`
      }, 50) // 50 msec delay is set because of IE11 problems

      if (this.trackExpand) {
        this.trackExpand()
      }
    },
    collapse() {
      const { content } = this.$refs

      const listener = () => {
        this.isAnimating = false

        content.removeEventListener('transitionend', listener, false)
      }

      content.addEventListener('transitionend', listener, false)

      this.contentStyle.height = `${content.scrollHeight}px`
      this.showStyles = true

      setTimeout(() => {
        this.isAnimating = true
        this.isExpanded = false
        this.contentStyle.height = `${this.minHeight}px`
      }, 50) // 50 msec delay is set because of IE11 problems

      if (this.trackCollapse) {
        this.trackCollapse()
      }
    }
  }
}
