<template>
  <div class="c-media-step-card u-flex u-flex-direction-column">
    <div class="c-media-step-card__media-wrapper u-mb--s">
      <Picture
        v-if="model.image && !model.video"
        v-bind="model.image"
        :mobile="[270,]"
        :tablet="[320,]"
        :desktop="[470,]"
        class="c-media-step-card__media"
      />
      <CVideo
        v-if="model.video"
        :src="model.video.src"
        :type="model.video.type"
        :poster="model.video.poster"
        autoplay
        loop
        muted
        class="c-media-step-card__media"
      />
    </div>
    <div class="c-media-step-card__body u-text-white">
      <h4
        v-if="model.title"
        class="c-media-step-card__heading u-font-bold u-font-size-h6 u-mb--xs"
      >
        {{ model.title }}
      </h4>
      <p
        v-if="model.text"
        class="c-media-step-card__description"
        v-html="formatLineBreaks(model.text)"
      />
    </div>
  </div>
</template>

<script>
import Picture from '@/components/Picture.vue'
import CVideo from '@/components/Video.vue'
import { formatLineBreaks } from '@/composition/htmlUtil'

export default {
  components: {
    Picture,
    CVideo
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      formatLineBreaks
    }
  }
}
</script>
