<template>
  <div
    v-if="showVideo"
    class="c-video"
  >
    <div
      v-if="isYoutube"
      class="c-video__iframe-container"
    >
      <iframe
        :src="src"
        title="Video"
        frameborder="0"
        allowfullscreen
      />
    </div>
    <template v-else>
      <video-js
        ref="playerRef"
        class="video-js"
      />
      <img
        v-if="showPoster && poster"
        :src="isMobile ? posterMobile : poster"
        :alt="heading"
      >
      <button
        v-if="showPlayBtnOverlay"
        class="c-video-play-button"
        @click="onHandleClick"
      >
        <Icon
          :icon="iconCaretRight"
          :size="32"
        />
      </button>
    </template>
  </div>
</template>

<script>

import {
  onBeforeUnmount, onMounted, ref, computed
} from 'vue'
import { useMq } from 'vue3-mq'
import videojs from 'video.js'
import Icon from '@/components/Icon.vue'
import iconCaretRight from '@/assets/img/icons/caret--right.svg'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  props: {
    src: {
      type: String,
      required: true
    },
    srcMobile: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      default: ''
    },
    posterMobile: {
      type: String,
      default: ''
    },
    playsinline: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    showBtnPlay: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: 'Default'
    },
    hideVideoForDesktop: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let player
    const playerRef = ref()
    const trackingDuration = []
    const availableTrackingDurations = [90, 75, 50, 25, 10]
    const showPlayBtnOverlay = ref(props.showBtnPlay)
    const showPoster = ref(props.showBtnPlay && !props.autoplay)
    const isYoutube = computed(() => props.type === 'youtube')
    const isMobile = useMq().current === 'mobile' || useMq().current === 'tablet'
    const showVideo = (!props.hideVideoForDesktop && !isMobile) || isMobile
    const videoOptions = computed(() => {
      const {
        src,
        srcMobile,
        playsinline,
        autoplay,
        loop,
        muted,
        controls
      } = props

      return {
        playsinline,
        autoplay,
        loop,
        muted,
        controls,
        liveui: controls,
        sources: [
          {
            src: isMobile ? srcMobile || src : src,
            type: 'application/x-mpegURL'
          }
        ]
      }
    })

    const onHandleClick = () => {
      if (showPlayBtnOverlay.value) {
        player.play()
        showPlayBtnOverlay.value = false
        gtmUtil.videoStart(props.heading)
      }
    }

    const getPercent = (tempPercent, percentValue) => {
      if (tempPercent > percentValue && !trackingDuration.includes(percentValue)) {
        return percentValue
      }
      return 0
    }

    onMounted(() => {
      if (showVideo && !isYoutube.value) {
        player = videojs(playerRef.value, videoOptions.value)

        if (!props.autoplay) {
          player.on('ended', () => {
            gtmUtil.videoComplete(props.heading)
          })
          player.on('play', () => {
            showPoster.value = false
          })
          player.on('timeupdate', () => {
            const tempPercent = Math.trunc((player.currentTime() * 100) / player.duration())
            let percent = 0
            for (let i = 0; i < availableTrackingDurations.length; i += 1) {
              percent = getPercent(tempPercent, availableTrackingDurations[i])
              if (percent !== 0) {
                break
              }
            }

            if (percent !== 0 && !trackingDuration.includes(percent)
              && availableTrackingDurations.includes(percent)) {
              gtmUtil.videoProgress(props.heading, percent)
              trackingDuration.push(percent)
            }
          })
        }
      }
    })

    onBeforeUnmount(() => {
      if (player) {
        player.dispose()
      }
    })

    return {
      iconCaretRight,
      playerRef,
      showPlayBtnOverlay,
      isYoutube,
      isMobile,
      showPoster,
      showVideo,
      onHandleClick
    }
  }
}
</script>
