import { reactive } from 'vue'

const searchConfigurationState = reactive({
  searchConfiguration: {},
  initialized: false
})

export default {
  searchConfigurationUse(configuration, baseUrl) {
    const state = searchConfigurationState

    if (configuration && !state.initialized) {
      state.searchConfiguration = configuration
      state.baseUrl = baseUrl
      state.initialized = true
    }

    return {
      searchConfiguration: state.searchConfiguration,
      baseUrl: state.baseUrl
    }
  }
}
