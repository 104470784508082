import searchQueryState from './searchQuery'
import searchConfig from './searchConfig'

export default {
  useRouterHelper(baseUrl) {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const { searchQuery } = searchQueryState.searchQueryUse()

    const searchParams = new URLSearchParams(window.location.search)

    function updateRoute() {
      // Set search term
      if (searchQuery.terms && searchQuery.terms.length) {
        const termsUrl = searchQuery.terms.join('+')
        searchParams.set('query', termsUrl)
      } else {
        searchParams.delete('query')
      }

      // Set filters
      const filterParams = searchQuery.activeCategories
        .map((category) => category.slug).filter((x) => x)

      if (filterParams.length) {
        searchParams.set('filters', filterParams.join('+'))
      } else {
        searchParams.delete('filters')
      }

      // Build URL to route to
      const searchParamsStr = decodeURIComponent(searchParams.toString())
      const newRelativePathQuery = searchParamsStr ? `${baseUrl}?${searchParamsStr}` : baseUrl
      window.history.replaceState(null, '', newRelativePathQuery)
    }

    function getRouteData() {
      const config = searchConfiguration

      const query = searchParams.get('query')
      const terms = (query || '').split(' ').filter((t) => t)

      const filters = searchParams.get('filters')
      const activeFiltersSlugs = (filters || '').split(' ').filter((t) => t)
      const activeCategories = config.categories
        .filter((x) => activeFiltersSlugs.includes(x.slug))

      return {
        activeCategories,
        searchType: config.type,
        terms
      }
    }

    return {
      getRouteData,
      updateRoute
    }
  }
}
