import { tns } from 'tiny-slider-rtl/src/tiny-slider.module'
import { computed } from 'vue'

const getDirection = () => document.querySelector('html').getAttribute('dir') || 'ltr'

const getDefaultSettings = (props, mySlider, mySliderControls) => ({
  container: mySlider,
  controlsContainer: mySliderControls,
  items: 1.1,
  loop: false,
  gutter: 16,
  nav: false,
  mouseDrag: true,
  textDirection: getDirection(),
  ...props
})

export function useSlider(props, mySlider, mySliderControls) {
  if (!mySlider || window.innerWidth < 768) return
  const standardSliderBreakpoints = {
    responsive: {
      576: {
        items: 2.5,
        mouseDrag: false,
        disable: false
      },
      768: {
        items: 4.3,
        mouseDrag: false,
        disable: false
      }
    },
    ...getDefaultSettings(props, mySlider, mySliderControls)
  }

  tns({
    ...standardSliderBreakpoints
  })
}

export function useFeatureSlider(props, mySlider, mySliderControls) {
  if (!mySlider || window.innerWidth < 768) return

  const standardSliderBreakpoints = {
    ...getDefaultSettings(props, mySlider, mySliderControls)
  }

  tns({
    ...standardSliderBreakpoints
  })
}

export function useMediaStepsSlider(props, mySlider, mySliderControls) {
  if (!mySlider || window.innerWidth < 768) return
  const standardSliderBreakpoints = {
    ...getDefaultSettings(props, mySlider, mySliderControls),
    responsive: {
      576: {
        disable: false
      }
    }
  }

  tns({
    ...standardSliderBreakpoints
  })
}

export function useProductsSlider(props, mySlider, mySliderControls) {
  if (!mySlider || window.innerWidth < 768) return
  const standardSliderBreakpoints = {
    ...getDefaultSettings(props, mySlider, mySliderControls),
    responsive: {
      576: {
        disable: false
      },
      768: {
        items: 3
      },
      1024: {
        items: 4
      }
    }
  }

  tns({
    ...standardSliderBreakpoints
  })
}

export function useCircleSlider(mySlider, mySliderControls) {
  if (window.innerWidth < 768) {
    return null
  }

  const defaultSettings = getDefaultSettings(null, mySlider, mySliderControls)
  const standardSliderBreakpoints = {
    ...defaultSettings,
    items: 2.5,
    gutter: 45,
    responsive: {
      480: {
        items: 3.5,
        mouseDrag: false,
        disable: false
      },
      576: {
        items: 4.5
      },
      768: {
        items: 5
      },
      1024: {
        items: 6
      },
      1200: {
        items: 7
      }
    }
  }

  return tns({
    ...standardSliderBreakpoints
  })
}

export function useSlidesConfig(props, count) {
  const displaySlider = computed(() => {
    if (props.initGrid) {
      return 'c-carousel-grid-mobile'
    }
    return ''
  })

  const alignedSlides = computed(() => {
    if (props.model.length <= count) {
      return 'u-width-text u-m--a'
    }
    return ''
  })

  return {
    displaySlider,
    alignedSlides
  }
}

export function fundamentalSlider(mySlider, mySliderControls, indexChangeCallback) {
  if (!mySlider) return
  const standardSlider = {
    container: mySlider,
    controlsContainer: mySliderControls,
    items: 1,
    loop: false,
    gutter: 10,
    nav: true,
    mouseDrag: true,
    preventScrollOnTouch: 'auto',
    navPosition: 'bottom',
    textDirection: getDirection()
  }

  const slider = tns({
    ...standardSlider
  })

  slider.events.on('indexChanged', indexChangeCallback)
}
