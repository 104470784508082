<template>
  <div
    v-if="model"
    class="c-carousel-wrapper u-mb--s"
  >
    <div
      ref="mySlider"
      class="c-carousel c-carousel-products"
      :class="alignedSlides"
    >
      <div
        v-for="(card, index) in model"
        :key="index"
        class="c-carousel__slide"
        :class="{ 'c-carousel__slide--single': cardCount === 1 }"
      >
        <ProductCard
          :index="index"
          :model="card"
        />
      </div>
    </div>
    <div
      ref="mySliderControls"
      class="c-carousel__controls"
    >
      <SliderControls />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useProductsSlider, useSlidesConfig } from '@/composition/slider'

import ProductCard from '@/components/blocks/ProductCard.vue'
import SliderControls from '@/components/navigation/SliderControls.vue'

export default {
  components: {
    SliderControls,
    ProductCard
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const cardCount = computed(() => props.model.length)

    onMounted(() => {
      useProductsSlider({
        disable: true,
        items: 3
      }, mySlider.value, mySliderControls.value.children[0])
    })

    return {
      cardCount,
      mySlider,
      mySliderControls,
      ...useSlidesConfig(props, 3)
    }
  }
}
</script>
