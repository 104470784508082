<template>
  <div
    v-if="inactiveFilters.length"
    class="c-search__filter-list"
  >
    <a
      v-for="(filter, index) in inactiveFilters"
      :key="index"
      :href="`${baseUrl}?filters=${filter.slug}`"
      class="c-button c-button--filter"
      @click.prevent="selectFilter(filter)"
    >
      {{ filter.name }}
    </a>
  </div>
</template>

<script>
import { computed } from 'vue'
import searchConfig from '@/composition/search/searchConfig'
import searchQueryState from '@/composition/search/searchQuery'
import gtmUtil from '@/utils/gtm-util'

export default {
  setup() {
    const { searchConfiguration, baseUrl } = searchConfig.searchConfigurationUse()
    const {
      activeCategories,
      addCategory
    } = searchQueryState.searchQueryUse()

    const inactiveFilters = computed(() => searchConfiguration.categories.filter((x) => !activeCategories.value.includes(x)))
    const selectFilter = (filter) => {
      addCategory(filter)
      gtmUtil.addFilter()
    }

    return {
      baseUrl,
      inactiveFilters,
      selectFilter
    }
  }
}
</script>
